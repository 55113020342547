<template>
  <div class="flexible-content">
    <!--Navbar-->
    <mdb-navbar class="flexible-navbar white" light position="top" scrolling>
      <mdb-navbar-brand href="https://mdbootstrap.com/docs/vue/" target="_blank"> SMA Khadijah </mdb-navbar-brand>
    </mdb-navbar>
    <!--/.Navbar-->
    <!-- Sidebar -->
    <div class="sidebar-fixed position-fixed ">
      <a class="logo-wrapper">
        <img alt="" class="img-fluid" src="./assets/IM.png">
      </a>
      <mdb-list-group class="list-group-flush">
        <router-link to="/dashboard" @click.native="activeItem = 1">
          <mdb-list-group-item :action="true" :class="activeItem === 1 && 'active'" class="white-text">
            <mdb-icon icon="chart-pie" class="mr-3" />
            Dashboard
          </mdb-list-group-item>
        </router-link>
      </mdb-list-group>
    </div>
    <!-- /Sidebar  -->
    <main>
      <div class="mt-5 p-5">
        <router-view />
      </div>
      <ftr color="primary-color-dark" class="text-center font-small darken-2 fixed-bottom">
        <hr class="my4">
        <div class="pb-4">
          <a href="https://imadani.com/" target="_blank"><i class="fas fa-laptop mr-3" /></a>
          <a href="https://www.youtube.com/channel/UCtQADiTfvUrIObloch8enlQ" target="_blank"><mdb-icon fab icon="youtube" class="mr-3" /></a>
          <a href="https://www.instagram.com/imadani_official/" target="_blank"><mdb-icon fab icon="instagram" class="mr-3" /></a>
          <a href="https://play.google.com/store/apps/details?id=com.ionicframework.madanischool250667&hl=in&gl=US" target="_blank"><i class="fab fa-android mr-3" /></a>
          <a href="https://apps.apple.com/id/app/madani-school-system/id1474623842" target="_blank"><i class="fab fa-apple mr-3" /></a>
        </div>
        <p class="footer-copyright mb-0 py-3 text-center">
          &copy; {{ new Date().getFullYear() }} Copyright:
          <a href="https://imadani.com"> Imadani.com </a>
        </p>
      </ftr>
    </main>
  </div>
</template>

<script>
import {
  mdbNavbar,
  mdbNavbarBrand,
  mdbIcon,
  mdbListGroup,
  mdbListGroupItem,
  mdbFooter
} from 'mdbvue'

export default {
  name: 'AdminTemplate',
  components: {
    mdbNavbar,
    mdbNavbarBrand,
    mdbListGroup,
    mdbListGroupItem,
    mdbIcon,
    ftr: mdbFooter
  },
  data() {
    return {
      activeItem: 1
    }
  },
  beforeMount() {
    this.activeItem = this.$route.matched[0].props.default.page
  }
}

</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
.navbar-light .navbar-brand {
  margin-left: 15px;
  color: #2196f3 !important;
  font-weight: bolder;
}
</style>

<style scoped>
main {
  background-color: #ededee;
}

.flexible-content {
  transition: padding-left 0.3s;
  padding-left: 270px;
}

.flexible-navbar {
  transition: padding-left 0.5s;
  padding-left: 270px;
}

.sidebar-fixed {
  left: 0;
  top: 0;
  height: 100vh;
  width: 270px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  z-index: 1050;
  background-color: rgb(28, 63, 170);
  padding: 1.5rem;
  padding-top: 0;
}

.sidebar-fixed .logo-wrapper img {
  width: 100%;
  padding: 2.5rem;
}

.sidebar-fixed .list-group-item {
  display: block !important;
  background-color: rgb(28, 63, 170);
  transition: background-color 0.3s;
}

.sidebar-fixed .list-group .active {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  background-color: rgba(255, 255, 255, 0.521);
  border-radius: 5px;
}

@media (max-width: 1199.98px) {
  .sidebar-fixed {
    display: none;
  }
  .flexible-content {
    padding-left: 0;
  }
  .flexible-navbar {
    padding-left: 10px;
  }
}
</style>
